import React from 'react';
import BaseLayoutTemplate from '../../components/environments/BaseLayoutTemplate';
import PageTitle from '../../components/atoms/PageTitle';
import styled from 'styled-components';
import { RouteComponentProps } from '@reach/router';
import { CROCCHA_API, MetaDesc, MetaOGP } from '../../utils';
import HeadBase from '../../components/molecules/HeadBase';
import ContactForm from 'app/src/components/organisms/ContactForm';

type ContactPageProps = RouteComponentProps<{ fromPage?: 'contact' }>;

const ContactPage: React.FC<ContactPageProps> = () => {
  return (
    <BaseLayoutTemplate>
      <HeadBase
        metas={{
          title: 'お問い合わせ | tryangle株式会社',
          description: MetaDesc,
          openGraph: {
            type: 'article',
            url: `${CROCCHA_API.SELF_DOMAIN}/contact/`,
            title: 'お問い合わせ | tryangle株式会社',
            description: MetaDesc,
            images: [
              {
                url: MetaOGP,
                width: 1200,
                height: 630,
                alt: 'tryangle株式会社',
              },
            ],
            site_name: 'tryangle株式会社',
          },
        }}
      />

      {/* アイキャッチ */}
      <PageTitle title='Contact' span='お問い合わせ' />

      <Maintenance>
        ただいまメンテナンス中です。
        <br />
        復旧は2月16日14時頃を予定しております。
      </Maintenance>
      {/* フォーム */}
      <ContactForm />
    </BaseLayoutTemplate>
  );
};

export default ContactPage;

const Maintenance = styled.section`
  margin: 0 auto;
  max-width: 800px;
  padding: 70px 1rem 100px;
  text-align: center;
  /* NOTE：メンテナンス時に無効にする */
  display: none;
`;
