import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';
import {
  mainColor,
  placeholderColor,
  breakpoint,
  primaryButtonStyle,
  borderColor,
} from '../../theme';
import Anchor from '../../atoms/Anchor';
import { RouteComponentProps } from '@reach/router';
import { CROCCHA_API } from '../../../utils';
import { navigate } from '@reach/router';

type FormData = {
  name: string;
  email: string;
  subject: string;
  body: string;
};

type Res = {
  result: 'success' | 'failed';
};

type Status = 'standby' | 'success' | 'error' | 'working';

const useFetchPost = (url: string, success: () => void) => {
  const [submitStatus, setSubmitStatus] = useState<Status>('standby');

  const sendMail = (data: FormData) => {
    setSubmitStatus('working');
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res: Res) => {
        if (res.result === 'success') {
          success();
          setSubmitStatus('success');
        } else {
          throw Error(res.result);
        }
      })
      .catch((e) => {
        console.error(e.message);
        setSubmitStatus('error');
      });
  };

  return { submitStatus, sendMail };
};

type ContactFormProps = RouteComponentProps<{ fromPage?: 'contact' }>;

const ContactForm: React.FC<ContactFormProps> = () => {
  const { handleSubmit, register, errors, watch } = useForm<FormData>({
    mode: 'onChange',
  });
  const url = CROCCHA_API.ALB_HOST + '/contacts/company';
  const { sendMail, submitStatus } = useFetchPost(url, () =>
    navigate('/contact/success', { state: { fromPage: 'contact' } })
  );
  const selectboxDefaultValue = '選択してください';
  const isSelectboxDefault =
    watch('subject') == null || watch('subject') === selectboxDefaultValue;

  const onSubmit = (data: FormData) => {
    const submitData: FormData = {
      ...data,
      body: data.body?.replace(/\r?\n/g, '<br>') ?? '',
      subject: isSelectboxDefault ? 'お問い合わせ' : data.subject,
    };
    sendMail(submitData);
  };

  return (
    <FormWrap>
      <P>
        当社へのお問い合わせ、求人に関するご質問は下記フォームよりご連絡ください。
        <br />
        各サービスについてもこちらからお問い合わせいただけます。
        <br />
        <br />
        公認ショップについては
        <StyledAnchor url='https://web.croccha.com/applyshop'>
          こちら
        </StyledAnchor>
        をご覧ください。
      </P>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldWrap>
          <LabelWrap>
            <Label htmlFor='name'>
              お名前<Required>必須</Required>
            </Label>
            {errors?.name?.message && (
              <ErrorText>{errors.name.message}</ErrorText>
            )}
          </LabelWrap>
          <Input
            type='text'
            name='name'
            placeholder='例: 山田花子'
            ref={register({
              required: '名前を入力してください',
              maxLength: {
                value: 100,
                message: '100文字以内で入力してください',
              },
            })}
            hasError={errors?.name?.message}
          />
        </FieldWrap>

        <FieldWrap>
          <LabelWrap>
            <Label htmlFor='email'>
              メールアドレス<Required>必須</Required>
            </Label>
            {errors?.email?.message && (
              <ErrorText>{errors.email.message}</ErrorText>
            )}
          </LabelWrap>
          <Input
            type='text'
            name='email'
            placeholder='例: xxx@sample.jp'
            ref={register({
              required: '必須項目です',
              maxLength: {
                value: 100,
                message: '100文字以内で入力してください',
              },
              pattern: {
                value:
                  /^[a-zA-Z0-9.!#$%&'*+/=?^_{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: 'メールアドレスの形式が正しくありません',
              },
            })}
            hasError={errors?.email?.message}
          />
        </FieldWrap>

        <FieldWrap>
          <Label htmlFor='subject'>お問い合わせ項目</Label>
          <div>
            <RadioInput
              type='radio'
              name='subject'
              value='サービスに関して（個人のお客様）'
              ref={register}
              id="subject-1"
            />
            <RadioLabel htmlFor='subject-1'>
              <span>サービスに関して（個人のお客様）</span>
            </RadioLabel>
          </div>
          <div>
            <RadioInput
              type='radio'
              name='subject'
              value='サービスに関して（法人のお客様）'
              ref={register}
              id="subject-2"
            />
            <RadioLabel htmlFor='subject-2'>
              <span>サービスに関して（法人のお客様）</span>
            </RadioLabel>
          </div>
          <div>
            <RadioInput
              type='radio'
              name='subject'
              value='採用に関して'
              ref={register}
              id="subject-3"
            />
            <RadioLabel htmlFor='subject-3'>
              <span>採用に関して</span>
            </RadioLabel>
          </div>
          <div>
            <RadioInput
              type='radio'
              name='subject'
              value='取材・掲載に関して'
              ref={register}
              id="subject-4"
            />
            <RadioLabel htmlFor='subject-4'>

              <span>取材・掲載に関して</span>
            </RadioLabel>
          </div>
          <div>
            <RadioInput
              type='radio'
              name='subject'
              value='その他のお問い合わせ'
              ref={register}
              id="subject-5"
            />
            <RadioLabel htmlFor='subject-5'>

              <span>その他のお問い合わせ</span>
            </RadioLabel>
          </div>
        </FieldWrap>

        <TextareaFieldWrap>
          <LabelWrap>
            <Label htmlFor='body'>
              お問い合わせ内容<Required>必須</Required>
            </Label>
            {errors?.body?.message && (
              <ErrorText>{errors.body.message}</ErrorText>
            )}
          </LabelWrap>
          <Input
            as='textarea'
            name='body'
            rows='10'
            placeholder=''
            ref={register({
              required: '必須項目です',
              maxLength: {
                value: 200,
                message: '入力文字数を少なくしてください', //現状のお問い合わせがほぼ営業メールのため、文字数をあえてvalidationに表示していない
              },
            })}
            hasError={errors?.body?.message}
          />
        </TextareaFieldWrap>

        {submitStatus === 'error' && (
          <div>
            <p>お問い合わせを送信できませんでした。</p>
            <p>
              恐れ入りますがしばらくお待ちいただき、後ほどお試しいただけますでしょうか
            </p>
          </div>
        )}

        <SubmitButton type='submit' value='送信' />
      </form>
    </FormWrap>
  );
};

export default ContactForm;

const FormWrap = styled.section`
  margin: 0 auto;
  max-width: 800px;
  padding: 70px 1rem 100px;
  /* NOTE：メンテナンス時に有効にする */
  /* display: none; */
`;

const P = styled.p`
  margin-bottom: 40px;
`;

const StyledAnchor = styled(Anchor)`
  text-decoration: underline;
`;

const LabelWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Label = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  white-space: nowrap;
`;

const Required = styled.span`
  margin-left: 5px;
  padding: 4px 8px;
  background: ${mainColor};
  border-radius: 11px;
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  vertical-align: top;
`;

const ErrorText = styled.span`
  margin-bottom: 0.5rem;
  display: inline-block;
  color: ${mainColor};
  font-size: 12px;
`;

const FieldWrap = styled.div`
  margin-bottom: 43px;
  width: 70%;
  @media ${breakpoint.sp} {
    width: 100%;
  }
`;

const inputMixinStyle = css`
  display: block;
  padding: 0.5rem 1rem;
  width: 100%;
  background: #f8f8f8;
  border: 1px solid #a2a1a1;
  border-radius: 5px;
  &::placeholder {
    color: ${placeholderColor};
  }
  &:focus {
    border: 1px solid #d1a4a1;
    box-shadow: 0px 2px 6px #3c0e0e1f;
    background: #fff;
  }
  ${(props: any) =>
    props.hasError &&
    css`
      border: 1px solid ${mainColor};
      background: #fff5f5;
      box-shadow: 0px 2px 6px #3c0e0e1f;
      color: ${mainColor};
    `}
`;

const Input: any = styled.input`
  ${inputMixinStyle};
`;

const TextareaFieldWrap = styled.div`
  margin-bottom: 40px;
  width: 100%;
`;

const SubmitButton = styled.input`
  ${primaryButtonStyle};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 120px;
`;

// ラジオボタン
const RadioInput = styled.input`
  -webkit-appearance: radio;
  display: none;

  :checked + label::after {
    opacity: 1;
  }
`;

const RadioLabel = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;

  span {
    margin-left: 30px;
  }

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid ${borderColor};
  }

  &::after {
    content: "";
    position: absolute;
    top: 6px;
    left: 4px;
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${mainColor};
    opacity: 0;
  }
`;




